.cycle_details{
    width: 100%;
    background-color: var(--bg2);
    color: var(--text);
    padding: 20px 0;
    min-height: calc(100vh - 60px);
}


.cycle_detail_cont{
    width: 100%;
    max-width: 600px;
    border-radius: 20px;
    background-color: var(--bg1);
    margin: auto;
    min-height: 400px;
    overflow: hidden;
    padding: 15px;
}

.cycle_detail_title{
    background-color: var(--primeCol10);
    padding: 15px;
    border-radius: 15px;
}

.cycle_detail_body{
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 20px;
}

.cycle_detail_body > div{
    padding: 15px 10px;
    width: 100%;

}

.cycle_detail_body > div > h4{
    font-size: 16px;
}

.cycle_detail_body > div > h4 > span{
    font-weight: normal;
    font-size: 18px;
}


.cycle_count_cont{
    padding-top: 20px;
    width: 100%;
}

.cycle_timer_cont{
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
}


@media (min-width : 1200px) {
    .cycle_details{
        max-width: 1200px;
        margin: auto;
    }


}
