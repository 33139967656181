
.cycles_container{
    width: 100%;
    margin-top: 60px;
    background-color: var(--bg1);
    padding: 20px;
    flex-wrap: wrap;
    position: relative;
    overflow-x: scroll;
    overflow: hidden;
}

.search_cont{
    padding: 10px 20px;
    border-radius: 10px;
    margin: auto;
    border: 1px;
}

.search_field{
    display: flex;
    align-items: center;
    border-radius: 15px;
    font-size: 20px;
    padding: 0 20px;
    background-color: white;
}

.add_client_btn{
    background-color: var(--primeCol);
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 10px;
    width: fit-content;
    color: white;
}

.add_client_btn:hover{
    background-color: var(--primeCol10);
}

.list_farmers_cont{
    width: 100%;
    margin-top: 20px;
    background-color: var(--bg1);
    padding: 20px;
    flex-wrap: wrap;
    position: relative;
    overflow-x: scroll;
    overflow: hidden;
}

table
{
    border-collapse: separate;
    border-spacing: 0;

}
table tr {
    border-radius: 100px;
}

tr:first-child th:first-child { border-top-left-radius: 10px; }
tr:first-child th:last-child { border-top-right-radius: 10px; }

tr:last-child th:first-child { border-bottom-left-radius: 10px; }
tr:last-child th:last-child { border-bottom-right-radius: 10px; }


@media (min-width : 1200px) {
    .cycles_container{
        max-width: 1200px;
        margin: auto;
    }


}
